/**
 * AspectRatioCover
 * 
 * @Author: Focci
 * @Date: 2023-06-22 22:55:27
 * @Last Modified by: Focci
 * @Last Modified time: 2023-06-22 22:55:27
 */

'use client'

import PropTypes from 'prop-types'
import Image from 'next/image'
import clsx from 'clsx'
import { useMemo, useState } from 'react'
import { isFunction } from 'lodash-es'

function AspectRatioCover({
  src = '',
  width = 400, // 0: 宽度为 100%
  height = 300,
  subtile = false, // 是否显示背景颜色
  // brand = 'https://s.hougarden.com/file/lo/go/logo-gray-default.png', // 图片加载时，显示的图片
  hoverZoomIn = false, // 鼠标经过是否放大显示
  transition = true, // 图片加载成功是否显示过度效果
  wrapperClassName = '', // 最外层 div样式
  className = 'object-cover',
  wrapperStyle,
  children,
  onLoad,
  ...imageProps
}) {
  const fullWidth = width <= 0
  const aspectRatio = useMemo(
    () => (fullWidth ? 'auto' : `${width} / ${height}`),
    [fullWidth, height, width]
  )

  const [ready, setReady] = useState(false)
  const imgProps = useMemo(() => {
    const tmp = {
      alt: '',
      onLoad: () => {
        setReady(true)
        isFunction(onLoad) && onLoad()
      },
      style: { aspectRatio },
      src: String(src),
    }
    
    if (fullWidth) {
      tmp.fill = true
    } else {
      tmp.width = width
      tmp.height = height
    }

    return { ...tmp, ...imageProps }
  }, [aspectRatio, fullWidth, height, imageProps, src, width, onLoad])

  return (
    <div
      style={{
        aspectRatio,
        // backgroundImage: brand ? `url(${brand})` : '',
        ...wrapperStyle
      }}
      className={clsx([
        'relative w-full h-full bg-cover bg-center',
        hoverZoomIn && 'overflow-hidden group/aspect-ratio',
        subtile && 'bg-cf0',
        wrapperClassName,
      ])}
    >
      {!!src && <Image
        className={clsx([
          'w-full h-full',
          transition && 'opacity-0',
          hoverZoomIn && 'transition-transform duration-1000 group-hover/aspect-ratio:scale-110',
          ready && transition && 'animate-fade-in',
          className
        ])}
        {...imgProps}
      />}
      {children}
    </div>
  )
}

AspectRatioCover.propTypes = {
  // src: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  subtile: PropTypes.bool,
  brand: PropTypes.string,
  hoverZoomIn: PropTypes.bool,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  wrapperStyle: PropTypes.object,
  children: PropTypes.node,
}

export default AspectRatioCover
