'use client'

import { WechatButtonLayout } from '@comp/page/common/login/button/wechat'
import useLogin from '@hook/useLogin'
import useMessage from '@hook/useMessage'
import SVGClose from '@img/close.svg'
import SVGWechat from '@img/wechat2.svg'
import { wechatAuth } from '@lib/fetch/login'
import { isWechat } from '@lib/utils'
import { get } from 'lodash-es'
import { useTranslations } from 'next-intl'
import { useCallback, useEffect, useMemo } from 'react'
import { useLocalStorage, useSearchParam, useSessionStorage } from 'react-use'

export default function WechatAuthContent({ zhLang }) {
  const iswechat = useMemo(() => isWechat(), [])
  const [isSetLang] = useLocalStorage('set_lang')
  const ts = useTranslations('common')
  const tsl = useTranslations('login')
  // 读取中文语言

  const [usedCode, setUsedCode] = useSessionStorage('wechatAuthUsedCode', '')
  const code = useSearchParam('code')
  const state = useSearchParam('state')
  const { message } = useMessage()

  const { user, setUser, openBindEmailDialog, openSigninDialog } = useLogin()
  const userId = useMemo(() => get(user, 'id'), [user])
  useEffect(() => {
    if (iswechat && code && state && !userId && usedCode !== code) {
      setUsedCode(code)
      wechatAuth(code).then((res) => {
        if (res) {
          if (get(res, 'headers.status') === 219) {
            openBindEmailDialog(res.token)
          } else {
            setUser(res)
          }
        }
      }).catch((err) => {
        message(err.message)
      })
    }
  }, [code, state, userId, setUser, message, usedCode, setUsedCode, openBindEmailDialog, iswechat])

  // 是否显示微信登录
  const [showDialog, setShowDialog] = useSessionStorage('wechatAuthDialog', true)
  const handleCloseDialog = useCallback(() => {
    setShowDialog(false)
  }, [setShowDialog])

  const handleLogin = useCallback(() => {
    openSigninDialog()
    handleCloseDialog()
  }, [openSigninDialog, handleCloseDialog])

  return iswechat && showDialog && !userId && (
    <div className="fixed z-[101] inset-0 bg-black bg-opacity-40 flex flex-col justify-end">
      <div className="bg-white rounded-t-lg px-3 pt-4 pb-14 relative">
        <button type="button" className="bg-transparent absolute right-3 top-2" onClick={handleCloseDialog}>
          <SVGClose className="fill-gray-400 w-4" />
        </button>
        <div className="text-center">
          <div className="text-f1.2 font-bold">{isSetLang ? tsl('title') : zhLang.login.title}</div>
          <div className="text-f.8 text-[#3B4450] mt-2">{isSetLang ? ts('wechatOneTapSub') : zhLang.common.wechatOneTapSub}</div>
        </div>
        <WechatButtonLayout>
          {
            ({ onClick }) => (
              <button
                type="button"
                className="bg-[#08BA3C] text-white w-full h-10 px-2 rounded-md text-f.8 flex items-center justify-center gap-x-1 mt-4"
                onClick={() => {
                  onClick()
                  handleCloseDialog()
                }}
              >
                <SVGWechat className="fill-white w-pix-18" />
                {isSetLang ? ts('wechatOneTapButton') : zhLang.common.wechatOneTapButton}
              </button>
            )
          }
        </WechatButtonLayout>
        <div className="text-center text-f.6 text-[#6F7485] mt-4">{isSetLang ? ts('wechatOneTapMore') : zhLang.common.wechatOneTapMore}</div>
        <button
          type="button"
          className="border bg-white w-full h-10 px-2 rounded-md text-f.8 flex items-center justify-center gap-x-1 mt-2"
          onClick={handleLogin}
        >
          {isSetLang ? tsl('accountLogin') : zhLang.login.accountLogin}
        </button>
      </div>
    </div>
  )
}
