/* eslint-disable no-template-curly-in-string */
/**
 * 页面顶部下载app
 * 
 * @Author: Focci
 * @Date: 2023-10-05 16:18:13
 * @Last Modified by: Focci
 * @Last Modified time: 2023-10-05 16:18:13
 */

'use client'

import AspectRatioCover from '@comp/AspectRatioCover'
import { Button, ButtonBase } from '@comp/material'
import useApp from '@hook/useApp'
import useFetch from '@hook/useFetch'
import { locales } from '@i18n/config'
import SVGStarEmpty from '@img/star-empty.svg'
import SVGStar from '@img/star.svg'
import { cookieGet, cookieSet, isWechat, isWindow } from '@lib/utils'
import CloseIcon from '@mui/icons-material/Close'
import Rating from '@mui/material/Rating'
import { Base64 } from 'js-base64'
import { last, size } from 'lodash-es'
import { useTranslations } from 'next-intl'
import { usePathname } from 'next/navigation'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

const HOUGARDEN_SHOWDOWNLOADAPP = 'HOUGARDEN_SHOWDOWNLOADAPP'

const customHost = {
  android: 'hougarden://com.hougarden.house',
  ios: 'hougarden://com.synnex.corp.hougarden.house'
}

// 需要拉起app的路由前缀
const allowedPathname = [
  '', 'map', 'search', 'property', 'news', 'topics', 'fm', 'estimate',
  'knowledge', 'live', 'opencourse', 'suburbs', 'suburb', 'agents', 'agent',
  'find-an-agent'
]

function detector() {
  const { maxTouchPoints } = window.navigator
  const { userAgent } = window.navigator

  if (/Android/i.test(userAgent)) {
    return 'android'
  }

  // maxTouchPoints is the only effective method to detect iPad iOS 13+
  // FMI https://developer.apple.com/forums/thread/119186
  if (
    (!window.MSStream && !/X11|Linux/i.test(userAgent) && maxTouchPoints && maxTouchPoints > 0)
    || /iPhone|iPad|iPod/i.test(userAgent)
  ) {
    return 'ios'
  }

  return ''
}

function getLaunchUrl() {
  let url
  try {
    const u = new URL(window.location.href)
    const type = detector()
    const host = customHost[type]

    host && (url = `${host}${u.pathname}${u.search}${u.hash}`)
  } catch (err) { /**/ }

  return url
}

// 在小程序中打开
function OpenWhitWeapp() {
  const tc = useTranslations('common')
  const pathname = usePathname()

  if (!isWechat()) return null

  let path = ''
  // 判断是否房源详情页
  if (pathname.startsWith('/property/')) {
    const uid = last(pathname.split('/'))
    if (size(uid) === 5) {
      path = `packageProperty/pages/detail/index?__bp__=${Base64.encode(JSON.stringify({ uid }))}`
    }
  }

  if (!path) return null

  return (
    <wx-open-launch-weapp
      path={path}
      appid={process.env.NEXT_PUBLIC_WECHAT_WEAPP_ID}
      username={process.env.NEXT_PUBLIC_WECHAT_WEAPP_USERNAME}
    >
      <script type="text/wxtag-template">
        <button
          type="button"
          style={{ width: '100%', height: '50px', border: '1px solid #20a4f8', color: '#20a4f8', backgroundColor: 'white', borderRadius: '4px', fontSize: '14px' }}
        >
          {tc('openInWeapp')}
        </button>
      </script>
    </wx-open-launch-weapp>
  )
}

function DownloadAppBarPanel({
  onOpen,
  onClose,
}) {
  const tc = useTranslations('common')

  const { data, isLoading } = useFetch('app-user-rating')

  const rating = useMemo(() => {
    const { averageUserRating: ra } = data || {}
    return /^[\d\\.]+$/.test(ra) ? ra.toFixed(1) : 4.9
  }, [data])

  return (
    <div className="relative md:hidden bg-white p-3 pb-6 rounded-t-md shadow-simple">
      <div className="flex gap-4">
        <div className="shrink-0 w-[2.7rem]">
          <AspectRatioCover
            src="https://s.hougarden.com/build/static/img/page/common/logo-bg-blue.png"
            width={54}
            height={54}
            alt="Hougarden"
            unoptimized
          />
        </div>
        <div className="flex-auto pr-6">
          <h5 className="text-f.9 font-medium">{tc('hgnz')}</h5>
          {
            isLoading
              ? <div className="bg-cf0 h-3 w-3/5 rounded-sm mt-1" />
              : (
                <div className="flex items-center gap-1 mt-1">
                  <span className="text-helper">{rating}</span>
                  <Rating
                    value={rating}
                    readOnly
                    icon={<span className="mx-0.5 text-[#fbcc61]"><SVGStar width=".7rem" /></span>}
                    emptyIcon={<span className="mx-0.5 text-[#fbcc61]"><SVGStarEmpty width=".7rem" /></span>}
                  />
                </div>
              )
          }
        </div>
      </div>
      <div className="mt-2">
        {tc('openWithHgApp')}
      </div>
      <div className="flex flex-col gap-2 mt-4">
        <Button
          size="large"
          onClick={onOpen}
          id="open-app-btn"
          className="h-10"
        >
          {tc('openInApp')}
        </Button>
        <OpenWhitWeapp />
        <Button variant="text" onClick={onClose}>
          {tc('ContinueViewingInBrowser')}
        </Button>
      </div>
      <div className="absolute top-2 right-2">
        <ButtonBase
          aria-label="Close"
          className="rounded-full bg-helper p-0.5"
          onClick={onClose}
        >
          <CloseIcon className="size-3 text-white" />
        </ButtonBase>
      </div>
    </div>
  )
}

export default function DownloadAppBar() {
  const pn = usePathname()
  const timer = useRef()
  const [show, setShow] = useState(false)
  const [wechatCover, setWechatCover] = useState('')
  const { isMobile } = useApp()

  const clearTimer = useCallback(() => timer.current && clearTimeout(timer.current), [])
  const handleClose = useCallback(() => {
    cookieSet(HOUGARDEN_SHOWDOWNLOADAPP, '1', { expires: 1 })
    clearTimer()
    setShow(false)
  }, [clearTimer])

  const handleClick = useCallback(async () => {
    if (isWechat()) {
      setWechatCover('https://s.hougarden.com/build/static/img/page/common/wechat_download_guide.jpg?a=11')
    } else {
      clearTimer()
      timer.current = setTimeout(() => {
        window.location.href = 'https://nz.hougarden.com/download'
      }, 3000)

      const launchUrl = getLaunchUrl()
      launchUrl && (window.location.href = launchUrl)
    }
  }, [clearTimer])

  useEffect(() => {
    if (isWindow) {
      try {
        const paths = pn.split('/')
        const firstHostPath = (locales.indexOf(paths[1]) > -1 ? paths[2] : paths[1]) || ''
        const closed = cookieGet(HOUGARDEN_SHOWDOWNLOADAPP)

        setShow(!closed && allowedPathname.indexOf(firstHostPath) > -1)
      } catch (err) { /**/ }
    }

    return clearTimer
  }, [clearTimer, pn])

  useEffect(() => {
    isWindow && window.addEventListener('visibilitychange', clearTimer, false)
    return () => {
      isWindow && window.removeEventListener('visibilitychange', clearTimer, false)
    }
  }, [clearTimer])

  if (!show || !isMobile) {
    return null
  }

  return (
    <>
      <div className="fixed left-0 right-0 bottom-0 z-50 opacity-0 animate-fade-in">
        <DownloadAppBarPanel
          onOpen={handleClick}
          onClose={handleClose}
        />
      </div>
      {wechatCover && (
        <div
          role="button"
          tabIndex="0"
          aria-label="Wechat Guide"
          className="fixed z-[1000] inset-0 bg-cover"
          style={{ backgroundImage: `url(${wechatCover})` }}
          onClick={() => setWechatCover('')}
          onKeyPress={() => setWechatCover('')}
        />
      )}
    </>
  )
}
