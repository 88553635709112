'use client'

import useLogin from '@hook/useLogin'
import useMessage from '@hook/useMessage'
import SVGWechat from '@img/wechat.svg'
import { wechatLogin } from '@lib/fetch/login'
import { wechatLoginAuth, wechatLoginCallbackPage } from '@lib/route/common'
import { isWechat, openCenteredWindow } from '@lib/utils'
import { LoadingButton } from '@mui/lab'
import { get, isFunction } from 'lodash-es'
import { useTranslations } from 'next-intl'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useAsyncFn, useUnmount } from 'react-use'

export default function WechatButton() {
  const ts = useTranslations('login')

  return (
    <WechatButtonLayout>
      {
        ({ onClick, wechatLoginState }) => (
          <LoadingButton
            type="button"
            loading={wechatLoginState.loading}
            className="flex items-center justify-center w-full h-pix-40 border rounded-[4px] bg-white text-[#3c4043] border-[#dadce0] border-solid"
            sx={() => ({ '& .MuiLoadingButton-loadingIndicator': { zIndex: 1 } })}
            onClick={onClick}
          >
            <SVGWechat className="w-pix-18 h-pix-18 mr-pix-8" />
            {ts('wechatLogin')}
          </LoadingButton>
        )
      }
    </WechatButtonLayout>
  )
}

export function WechatButtonLayout({
  children,
}) {
  const { setUser, closeDialog, openBindEmailDialog } = useLogin()
  const wechatLoginWindowRef = useRef(null)
  const [state, setState] = useState()
  const { message } = useMessage()

  // 微信登录
  const [wechatLoginState, handleWechatLogin] = useAsyncFn(async (code) => {
    const res = wechatLogin(code).catch((err) => {
      message(err.message)
      return Promise.reject(err)
    })
    return res
  })

  // 注册微信登录回调
  useEffect(() => {
    // window.wechatLoginCallback = async ({ code, state: callbackState }) => {
    //   if (wechatLoginWindowRef.current) {
    //     wechatLoginWindowRef.current.close()
    //   }

    //   if (callbackState === state) {
    //     const res = await handleWechatLogin(code)
    //     if (res) {
    //       if (get(res, 'headers.status') === 219) {
    //         openBindMobileDialog(res.token)
    //       } else {
    //         setUser(res)
    //         closeDialog()
    //       }
    //     }
    //   } else {
    //     message('state error')
    //   }
    // }
    const callback = async (params) => {
      const { code, state: callbackState } = get(params, 'data') || {}
      if (!code || !callbackState) return

      if (wechatLoginWindowRef.current) {
        wechatLoginWindowRef.current.close()
      }

      if (callbackState === state) {
        const res = await handleWechatLogin(code)
        if (res) {
          if (get(res, 'headers.status') === 219) {
            openBindEmailDialog(res.token)
          } else {
            setUser(res)
            closeDialog()
          }
        }
      } else {
        message('state error')
      }
    }
    window.addEventListener('message', callback)
    return () => {
      window.removeEventListener('message', callback)
    }
  }, [state, handleWechatLogin, openBindEmailDialog, closeDialog, setUser, message])

  // 移除微信登录回调
  useUnmount(() => {
    window.wechatLoginCallback = undefined
    if (wechatLoginWindowRef.current) {
      wechatLoginWindowRef.current.close()
    }
  })

  // 打开微信登录二维码弹窗
  const onClick = useCallback(() => {
    if (isWechat()) {
      window.location.href = wechatLoginAuth()
    } else {
      const newState = Math.random().toString(36).slice(2)
      setState(newState)
      wechatLoginWindowRef.current = openCenteredWindow(wechatLoginCallbackPage({ state: newState, origin: window.location.origin }), 'Wechat Login', 600, 400)
    }
  }, [wechatLoginWindowRef, setState])

  return (
    isFunction(children) ? children({ onClick, wechatLoginState }) : children
  )
}
